import React, {useCallback, useMemo, useRef} from 'react';
import {createContext, useState} from "react";
import {
  CModal, CModalBody, CModalHeader, CModalFooter, CButton,
} from "@coreui/react";

const ConfirmContext = createContext(undefined);

const ConfirmDialogProvider = ({ children }) => {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();
  const confirm = useCallback(
    (data) => {
      return new Promise((resolve) => {
        setState({ ...data, isOpen: true });
        fn.current = (choice) => {
          resolve(choice);
          setState({ isOpen: false });
        };
      });
    },
    [setState]
  );

  const memoedValue = useMemo(
    () => ({
      confirm
    }),
    [confirm]
  );

  return (
    <ConfirmContext.Provider value={memoedValue}>
      {children}
      <CModal
        show={state.isOpen}
        onClose={() => fn.current(false)}
      >
        <CModalHeader closeButton>{(state.hasOwnProperty("title") && state.title !== undefined) ? state.title : ""}</CModalHeader>
        <CModalBody>
          {(state.hasOwnProperty("description") && state.description !== undefined) ? state.description : ""}
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={() => fn.current(true)}>
            {(state.hasOwnProperty("confirmBtnLabel") && state.confirmBtnLabel !== undefined) ? state.confirmBtnLabel : "Confirm"}
          </CButton>{' '}
          <CButton
            color="secondary"
            onClick={() => fn.current(false)}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>
    </ConfirmContext.Provider>
  );
}

export { ConfirmContext, ConfirmDialogProvider };
